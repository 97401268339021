import { useEthers } from "@usedapp/core";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import CalcStatCharts from "../components/calculator/CalcStatCharts";
import Stat from "../components/dashboard/Stat";
import { GetStakedBalance, useGetBalance } from "../hooks/usedapphooks";
import nFormatter from "../hooks/useNumberFormatter";
import "../styles/calculator.scss";

const Calculator = () => {
  const { account } = useEthers();
  const mybalance = useGetBalance(account) * 0.000000000000000001;
  const expo_staked = GetStakedBalance(account) * 0.000000000000000001;
  const totalbalance = expo_staked + mybalance;
  const [balance, setBalance] = useState(-1);
  const [dividend, setDividend] = useState(50000);
  const [volume, setVolume] = useState(150000);
  const share = balance / (10 ** 12 - 183277026497);
  const myshare = totalbalance / (10 ** 12 - 183277026497);

  const balanceStats = [
    {
      name: "My Balance",
      stat: balance >= 0 ? nFormatter(balance, 0) : nFormatter(totalbalance, 0),
    },
  ];
  const firstStats = [
    {
      name: "My Share",
      stat:
        (balance >= 0
          ? nFormatter(share * 100, 7)
          : nFormatter(myshare * 100, 7)) + " %",
    },
  ];

  const dailyStats = [
    {
      name: "Daily Volume",
      stat: nFormatter(volume, 0),
    },
  ];

  const monthlyStats = [
    {
      name: "Monthly Dividend",
      stat: nFormatter(dividend, 0),
    },
  ];
  return (
    <>
      <div className="statsWrap">
        <Row>
          {balanceStats.map((item, i) => {
            return (
              <Col md="6" xl>
                <div key={i}>
                  {/* <Stat name={item.name} stat={item.stat} /> */}
                  <Stat
                    name={item.name}
                    stat={
                      <input
                        className="form-control"
                        type="number"
                        defaultValue={item.stat}
                        value={balance >= 0 ? balance : totalbalance}
                        // placeholder="My Balance"
                        onChange={(e) => setBalance(e.target.value)}
                      ></input>
                    }
                  />
                </div>

                <div className="formRangeContainer">
                  <Form.Text className="cfFormSmall" id="currencyText">
                    EXPO
                  </Form.Text>
                  <Form.Range
                    className="formRangeSlider daily-range"
                    max={2 * 10 ** 10}
                    step={100}
                    value={balance >= 0 ? balance : totalbalance}
                    onChange={(e) => setBalance(e.target.value)}
                  />
                </div>
              </Col>
            );
          })}
          {firstStats.map((item, i) => {
            return (
              <Col md="6" xl key={i}>
                <div>
                  <Stat name={item.name} stat={item.stat} />
                </div>
              </Col>
            );
          })}

          {dailyStats.map((item, i) => {
            return (
              <Col md="6" xl>
                <div key={i}>
                  <Stat name={item.name} stat={item.stat} />
                </div>

                <div className="formRangeContainer">
                  <Form.Text className="cfFormSmall" id="currencyText">
                    USD ($)
                  </Form.Text>
                  <Form.Range
                    className="formRangeSlider daily-range"
                    // max={10 ** 6}
                    max={5000000}
                    step={100}
                    value={volume}
                    onChange={(e) => setVolume(e.target.value)}
                  />
                </div>
              </Col>
            );
          })}

          {monthlyStats.map((item, i) => {
            return (
              <Col md="6" xl>
                <div key={i}>
                  <Stat name={item.name} stat={item.stat} />
                </div>

                <div className="formRangeContainer">
                  <Form.Text className="cfFormSmall" id="currencyText">
                    USD ($)
                  </Form.Text>
                  <Form.Range
                    className="formRangeSlider monthly-range"
                    // max={10 ** 6}
                    max={5000000}
                    step={100}
                    value={dividend}
                    onChange={(e) => setDividend(e.target.value)}
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
      {balance >= 0
        ? CalcStatCharts(share, volume, dividend)
        : CalcStatCharts(myshare, volume, dividend)}
    </>
  );
};
export default Calculator;
