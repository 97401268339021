import React, { useState } from "react";
import { Outlet } from "react-router";
import Footer from "../components/Footer";
import "../styles/dashboard.scss";
import Layout from "./DashboardWrapper";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

const Dashboard = () => {
  const [close, setClose] = useState(false);

  return (
    <Layout>
      <div className="dashboardPageWrap">
        <Sidebar state={close} setClose={setClose} />
        <div className="contentWrap">
          {/* <div className="navContent"> */}
          <Navbar toggleState={close} toggleFunc={setClose} />
          <div className={`outletDiv ${close ? "" : "isBlurred"}`}>
            <Outlet />
          </div>
          {/* </div> */}
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
