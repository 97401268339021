import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "../styles/sidebar.scss";

const Sidebar = ({ state, setClose }) => {
  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");
  const currentLocation = splitLocation[2];

  const [selectedBtn, setSelectedBtn] = useState(
    currentLocation ? currentLocation : "dashboardeth"
  );

  return (
    <div className={`sidebarNav ${state && "sidebaropen"}`}>
      <div className="sideBarContainer">
        <div className="logoContainer">
          <Link to="/">
            <img src="/images/sidebarLogo.png" alt="logo" />
          </Link>
        </div>

        <div className="buttonGrid">
          {/*  <h3> HOME </h3> */}
          <NavLink to="/dashboard/eth">
            <div
              onClick={() => {
                setSelectedBtn("dashboardeth");
                if (window.innerWidth < 800) {
                  setClose((prevState) => !prevState);
                }
              }}
              className={`sideBtns ${
                selectedBtn === "dashboardeth" ? "selectedBtn" : ""
              } `}
            >
              DASHBOARD ETH
            </div>
          </NavLink>

          <NavLink to="/dashboard/arb">
            <div
              onClick={() => {
                setSelectedBtn("dashboardarb");
                if (window.innerWidth < 800) {
                  setClose((prevState) => !prevState);
                }
              }}
              className={`sideBtns ${
                selectedBtn === "dashboardarb" ? "selectedBtn" : ""
              } `}
            >
              DASHBOARD ARB
            </div>
          </NavLink>

          {/* <NavLink to="/dashboard/treasury">
            <div
              onClick={() => {
                setSelectedBtn("farm");
                if (window.innerWidth < 800) {
                  setClose(!state);
                }
              }}
              className={`sideBtns ${
                selectedBtn === "farm" ? "selectedBtn" : ""
              } `}
            >
              TREASURY
            </div>
          </NavLink> */}

          {/* <NavLink to="/dashboard/farm">
            <div
              onClick={() => {
                setSelectedBtn("treasury");
                if (window.innerWidth < 800) {
                  setClose((prevState) => !prevState);
                }
              }}
              className={`sideBtns ${
                selectedBtn === "treasury" ? "selectedBtn" : ""
              } `}
            >
              TREASURY old
            </div>
          </NavLink> */}

          {/* <NavLink to="/dashboard/calculator">
            <div
              onClick={() => {
                setSelectedBtn("calculator");
                if (window.innerWidth < 800) {
                  setClose((prevState) => !prevState);
                }
              }}
              className={`sideBtns ${
                selectedBtn === "calculator" ? "selectedBtn" : ""
              } `}
            >
              CALCULATOR
            </div>
          </NavLink> */}
          <NavLink to="/dashboard/staking">
            <div
              onClick={() => {
                setSelectedBtn("staking");
                if (window.innerWidth < 800) {
                  setClose((prevState) => !prevState);
                }
              }}
              className={`sideBtns ${
                selectedBtn === "staking" ? "selectedBtn" : ""
              } `}
            >
              STAKING ETH
            </div>
          </NavLink>

          <NavLink to="/dashboard/alpha">
            <div
              onClick={() => {
                setSelectedBtn("alpha");
                if (window.innerWidth < 800) {
                  setClose(!state);
                }
              }}
              className={`sideBtns ${
                selectedBtn === "alpha" ? "selectedBtn" : ""
              } `}
            >
              ALPHA PORTAL
            </div>
          </NavLink>

       
        </div>
        <span></span>

        <div className="linksContainer">
          <h5> LINKS </h5>
          <ul className="links-list">
          <li>
              <span className="logoSpan">
                {" "}
                <img
                  src="/images/sidebar-assets/zapper.svg"
                  alt="logo"
                />{" "}
              </span>{" "}
              <a
                href="https://zapper.xyz/bundle/0xd4f9e835087cb8a9ea2fe87c0f902c04f2b0cecf,0xcd56caf05c2b64794c163149758229f44e076a1c?label=Bundle%201&id=0xcae8790f569b2a8dafcd1343e40dc9239124e6d4&tab=dashboard"
                target="_blank"
                rel="noreferrer"
              >
                Treasury Balance
              </a>{" "}
            </li>
            <li>
              <span className="logoSpan">
                {" "}
                <img
                  src="/images/sidebar-assets/uniswap-uni-logo.svg"
                  alt="logo"
                />{" "}
              </span>{" "}
              <a
                href="https://app.sushi.com/swap?outputCurrency=0xcb24b141ecaaad0d2c255d6f99d6f4790546a75c"
                target="_blank"
                rel="noreferrer"
              >
                Buy on Sushiswap
              </a>{" "}
            </li>
            <li>
              {" "}
              <span className="logoSpan">
                {" "}
                <img src="/images/sidebar-assets/dextools.svg" alt="logo"  />
              </span>
              <a
                href="https://dexscreener.com/arbitrum/0xE56C8cFaAA68469990C40844a89205C4fD7e25A4"
                target="_blank"
                rel="noreferrer"
              >
                Price Chart
              </a>{" "}
            </li>
            <li>
              <span className="logoSpan">
                {" "}
                <img src="/images/sidebar-assets/CoinGecko.svg" alt="logo" />
              </span>
              <a
                href="https://www.coingecko.com/en/coins/exponential-capital"
                target="_blank"
                rel="noreferrer"
              >
                CoinGecko
              </a>{" "}
            </li>
            <li>
              <span className="logoSpan">
                {" "}
                <img
                  src="/images/sidebar-assets/CoinMarketCap.svg"
                  alt="logo"
                />
              </span>
              <a
                href="https://coinmarketcap.com/currencies/exponential-capital-v2/"
                target="_blank"
                rel="noreferrer"
              >
                CoinMarketCap
              </a>{" "}
            </li>
            <li>
              <span className="logoSpan">
                {" "}
                <img
                  src="/images/sidebar-assets/Etherscan.svg"
                  alt="logo"
                />{" "}
              </span>{" "}
              <a
                href="https://arbiscan.io/token/0xCB24B141ECaaAd0d2c255d6F99d6f4790546a75c"
                target="_blank"
                rel="noreferrer"
              >
                Arbiscan
              </a>{" "}
            </li>
          </ul>
        </div>
      </div>
      <div className="parent">
        <div className="child one"></div>
        <div className="child two"></div>
        <div className="child three"></div>
      </div>
    </div>
  );
};

export default Sidebar;
