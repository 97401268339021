import React from "react";
import { Button, Row } from "react-bootstrap";
import "../../styles/alpha/formfront.scss";

const FormFront = ({ func }) => {
  return (
    <div className="frontformWrap">
      <Row className="crossLogo">
        <img src="/images/cross-small.svg" alt="cross" />
      </Row>
      <Row className="fronttext">
        <p>
          Welcome to the Exponential Capital Alpha Portal. We value our
          community insights, so if you are an EXPO holder and want to share
          projects you feel the team should look into, please fill in this form.
        </p>
      </Row>
      <div className="showFormBtn">
        <Button onClick={func}>Show form</Button>
      </div>
    </div>
  );
};

export default FormFront;
