import { useEthers } from "@usedapp/core";
import React, { useState } from "react";
import { Button, Form, ProgressBar, Row, Stack } from "react-bootstrap";
import AfterForm from "../components/alpha/AfterForm";
import ConnectToShowForm from "../components/alpha/ConnectToShowForm";
import FormFront from "../components/alpha/FormFront";
import { GetStakedBalance, useGetBalance } from "../hooks/usedapphooks";
import nFormatter from "../hooks/useNumberFormatter";
import "../styles/alpha/alpha.scss";
import "../styles/alpha/formfront.scss";

const questions = [
  {
    q: "What is the project name?",
    name: "entry.1905495381",
  },
  {
    q: "What’s unique about the project?",
    name: "entry.1016752064",
  },
  {
    q: "What is the Tokenomics breakdown?",
    name: "entry.993426856",
  },
  {
    q: "Are the socials and website live?",
    name: "entry.627807673",
  },
  {
    q: "Telegram channel link:",
    name: "entry.102123564",
  },
  {
    q: "Twitter profile link:",
    name: "entry.805442450",
  },
  {
    q: "Website link:",
    name: "entry.2129861421",
  },
  {
    q: "Who are the team?",
    name: "entry.990729632",
  },
  {
    q: "Is the team doxxed?",
    name: "entry.2012377575",
  },
  {
    q: "Are there plans for third party KYC?",
    name: "entry.724128771",
  },
  {
    q: "How much is the initial liquidity?",
    name: "entry.2062021471",
  },
  {
    q: "What is the market cap at launch?",
    name: "entry.1438913736",
  },
  {
    q: "If applicable, what are the pre-sale terms and conditions?",
    name: "entry.412395030",
  },
  {
    q: "Can the team provide any previous projects?",
    name: "entry.475979280",
  },
];

const Alpha = () => {
  const [submitted, setSubmitted] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [index, setIndex] = useState(0);

  const [progress, setProgress] = useState(10);
  const [showConnectBtn, setShowConnectBtn] = useState(false);
  const { account } = useEthers();
  const mybalance = useGetBalance(account) * 0.000000000000000001;
  const expo_staked = GetStakedBalance(account) * 0.000000000000000001;
  const totalbalance = mybalance + expo_staked;
  // ------------------------------- ---------------------------------------

  //state for form data
  const [formData, setFormData] = useState({
    "entry.1905495381": "",
    "entry.1016752064": "",
    "entry.993426856": "",
    "entry.627807673": "",
    "entry.990729632": "",
    "entry.2012377575": "",
    "entry.724128771": "",
    "entry.2062021471": "",
    "entry.1438913736": "",
    "entry.412395030": "",
    "entry.475979280": "",
    "entry.102123564": "",
    "entry.805442450": "",
    "entry.2129861421": "",
  });

  async function handleSubmitForm(e) {
    e.preventDefault();
    setSubmitted(true);

    // let url = `https://docs.google.com/forms/u/0/d/e/1FAIpQLSekAL9DzMW2PFS1jsqflC8SUePRFElI2DN8ub03UP2f0M1Xag/formResponse?entry.1016752064=${formData["entry.1016752064"]}&entry.993426856=${formData["entry.993426856"]}&entry.627807673=${formData["entry.627807673"]}`;
    let url =
      // "https://docs.google.com/forms/u/0/d/e/1FAIpQLSekAL9DzMW2PFS1jsqflC8SUePRFElI2DN8ub03UP2f0M1Xag/formResponse?";
      "https://docs.google.com/forms/d/e/1FAIpQLScNLtkQw3uysyRTyLRjTNT-bWymrL4U06FQ2joy_S1DvMBuIQ/formResponse?";
    let i = 0;
    for (i; i < questions.length - 1; i++) {
      url += questions[i].name + "=" + formData[questions[i].name] + "&";
    }
    url += questions[i].name + "=" + formData[questions[i].name];

    const res = await fetch(url, {
      method: "POST",
      headers: {
        // 'Content-Type': 'application/json'
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    // console.log(await res.json());
  }

  const handleInputData = (input) => (e) => {
    // input value from the form

    const { value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && index !== questions.length - 1) {
      e.preventDefault();
      nextQues();
    }
  };

  // ------------------------------- ---------------------------------------

  const toPercentage = (num) => {
    return ((num + 1) * 100) / questions.length;
  };
  const checkNumber = (number) => {
    if (number < 0) {
      return 0;
    }

    if (number > questions.length - 1) return questions.length - 1;

    if (number === 0) setProgress(10);
    setProgress(parseInt(toPercentage(number)));
    return number;
  };
  const nextQues = () => {
    setIndex((index) => {
      let newIndex = index + 1;
      return checkNumber(newIndex);
    });
  };
  const prevQues = () => {
    setIndex((index) => {
      let newIndex = index - 1;
      return checkNumber(newIndex);
    });
  };

  const toggleShowForm = () => {
    if (account) {
      setShowForm(!showForm);
    } else {
      setShowConnectBtn(true);
      setShowForm(true);
    }
  };

  if (showForm === false) {
    return (
      <div className="alphaWrapper">
        <FormFront func={toggleShowForm} />
      </div>
    );
  } else if (showConnectBtn && !account) {
    return (
      <div className="alphaWrapper">
        <ConnectToShowForm />
      </div>
    );
  } else if (account && totalbalance > 0) {
    return (
      <div className="alphaWrapper">
        {submitted ? (
          <AfterForm />
        ) : (
          <div className="alphaInnerWrap">
            <ProgressBar
              className="progressBar"
              variant="danger"
              now={progress}
            />

            <div className="alphaFormWrap">
              <Form
                onSubmit={handleSubmitForm}
                onKeyDown={handleKeyDown}
                target="_self"
              >
                <Form.Group className="formElement">
                  <Form.Label>{questions[index].q}</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData[questions[index].name]}
                    onChange={handleInputData(questions[index].name)}
                    name={questions[index].name}
                    placeholder="Your Answer"
                    autoComplete="off"
                  />
                </Form.Group>
                <Row className="btnWrap">
                  <Stack
                    direction="horizontal"
                    gap={3}
                    style={{ justityContent: "space-evenly" }}
                  >
                    <Button
                      onClick={prevQues}
                      className={index === 0 ? "btnInactive" : "btnActive"}
                    >
                      Prev
                    </Button>
                    {index === questions.length - 1 ? (
                      <div className="submitWrap">
                        <Button type="submit" className="submitBtn">
                          Submit
                        </Button>
                      </div>
                    ) : (
                      <Button onClick={nextQues} className="btnActive">
                        Next
                      </Button>
                    )}
                  </Stack>
                </Row>
              </Form>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="alphaWrapper">
        <div className="frontformWrap">
          <Row className="crossLogo">
            <img src="/images/cross-small.svg" alt="cross" />
          </Row>
          <Row className="fronttext">
            <p>ALPHA Portal is exclusively available for EXPO Holders.</p>
          </Row>
        </div>
      </div>
    );
  }
};

export default Alpha;
