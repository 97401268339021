import React from "react";

const TitlePic = () => {
  return (
    <>
      <div className="expoPic">
        <img className="expoImage" src="/images/titlePic.svg" alt="titlePic" />
      </div>
      {/* <div className="expoImagephone">
        <img src="/images/logophone.svg" alt="titlePic" />
      </div> */}
    </>
  );
};

export default TitlePic;
