import { useCoingeckoTokenPrice } from "@usedapp/coingecko";
import { useEthers, useTokenAllowance } from "@usedapp/core";
import React, { useState } from "react";
import { Col, Nav, Row, Tab, Alert } from "react-bootstrap";
import Stat from "../components/dashboard/Stat";
import { StakeButton } from "../components/staking/stakebutton";
import { UnStakeButton } from "../components/staking/unstakebutton";
import { expoConfig } from "../config";
// import StepForm from "../components/staking/stepForm/StepForm";
import {
  GetStakedAPY,
  GetStakedBalance,
  GettotalDeposited,
  useApprove,
  useGetBalance,
  useStake,
  useUnstake,
} from "../hooks/usedapphooks";
import nFormatter from "../hooks/useNumberFormatter";
import "../styles/staking.scss";
import { ethers } from "ethers";
// import { formatUnits } from "ethers/lib/utils";

const Staking = () => {
  const [section, setSection] = useState("tab1");
  const [inputNum, setInputNum] = useState("0.00");
  const [inputUnstakeNum, setUnstakeInputNum] = useState("0.00");
  const [isDisabled, setDisabled] = useState(false);
  const [isValid, setIsValid] = useState(false);

  // return (
  //   {allowance && <p>Remaining allowance: {formatUnits(allowance, 18)} tokens</p>}
  // )

  const { account } = useEthers();

  const TOKEN_ADDRESS = expoConfig.smartContract;
  const SPENDER_ADDRESS = expoConfig.staking_contract;
  const allowance = useTokenAllowance(TOKEN_ADDRESS, account, SPENDER_ADDRESS);
  // console.log(TOKEN_ADDRESS, account, SPENDER_ADDRESS);
  const eth_contract = expoConfig.ethereumContract;
  const eth_price = useCoingeckoTokenPrice(eth_contract, "usd");
  const expo_holdings = useGetBalance(account) * 0.000000000000000001;
  const expo_staked = GetStakedBalance(account) * 0.000000000000000001;
  const expo_deposited = GettotalDeposited() * 0.000000000000000001;
  const expo_apy = GetStakedAPY();
  const expo_stakingpool =
    ((expo_staked * 0.000000000000000001) /
      (expo_deposited * 0.000000000000000001)) *
    100;
  const { state, send: approvesend } = useApprove();

  const { state: stakestate, send } = useStake();
  const { state: unstakestate, send: unstakesend } = useUnstake();

  function handlestake(amount) {
    (async () => {
      await send(
        // ethers.BigNumber.from(
        Number(amount).toFixed().toString() + "0".repeat("18")
        // )
      );
      console.log(Number(amount).toFixed().toString() + "0".repeat("18"));
      handleSubmission(stakestate);
    })();
  }

  function handleunstake(amount) {
    (async () => {
      await unstakesend(
        // ethers.BigNumber.from(
        Number(amount).toFixed().toString() + "0".repeat("18")
        // )
      );
      handleSubmission(unstakestate);
    })();
  }
  function handleSubmission(state) {
    if (state.status == "Exception") {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(false);
  };

  const handleInputChange = (e) => {
    setInputNum(e.target.value);
  };

  const handleMaxInput = () => {
    setInputNum(expo_holdings);
  };

  const handleUnstakeInputChange = (e) => {
    setUnstakeInputNum(e.target.value);
  };

  const handleMaxUnstakeInput = () => {
    setUnstakeInputNum(expo_staked);
  };

  const stakeStats = [
    {
      name: "Your EXPO Balance",
      stat: nFormatter(expo_holdings, 2) + " EXPO",
    },
    {
      name: "Your EXPO Staked",
      stat: nFormatter(expo_staked, 2) + " EXPO",
    },
    {
      name: "% Staking Pool",
      stat: expo_stakingpool.toFixed(2) + "%",
    },
    {
      name: "APY",
      stat: nFormatter(expo_apy, 2) + "%",
    },
  ];

  return (
    <div className="stakeStatsWrap">
      <Row>
        {stakeStats.map((item, i) => {
          return (
            <Col md="6" xl key={i}>
              <div>
                <Stat name={item.name} stat={item.stat ? item.stat : "-"} />
              </div>
            </Col>
          );
        })}
      </Row>

      <div className="stakingWrapper">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav variant="tabs">
            <Nav.Item
              onClick={() => setSection("tab1")}
              className={`${section === "tab1" ? "activeTab" : ""}`}
            >
              <Nav.Link eventKey="first">STAKE</Nav.Link>
            </Nav.Item>
            <Nav.Item
              onClick={() => setSection("tab2")}
              className={`${section === "tab2" ? "activeTab" : ""}`}
            >
              <Nav.Link eventKey="second">UNSTAKE</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="first" className="tabPaneWrapper">
              <form onSubmit={handleSubmit}>
                <div className="stakeCardWrapper">
                  <div className="stakeCardWrapper-left">
                    <Row className="headerRow">
                      <Col>AMOUNT</Col>
                      <Col>EXPO</Col>
                    </Row>

                    <Row className="secondRow">
                      <Col className="firstCol">
                        <input
                          className="input-field"
                          type="number"
                          value={inputNum}
                          onChange={handleInputChange}
                          placeholder="0.00"
                        />
                      </Col>
                      <Col>
                        {" "}
                        <button onClick={handleMaxInput} className="max-btn">
                          {" "}
                          MAX{" "}
                        </button>
                      </Col>
                    </Row>
                  </div>

                  {allowance * 0.000000000000000001 > 0 ? (
                    // {true ? (
                    <div className="stakeCardWrapper-right">
                      {/* <StakeButton amount={inputNum} />
                       */}
                      <button
                        className="btns"
                        onClick={() => handlestake(inputNum)}
                      >
                        STAKE{" "}
                      </button>
                    </div>
                  ) : (
                    <div className="stakeCardWrapper-right">
                      <button
                        className="btns approve-btn"
                        onClick={() =>
                          approvesend(
                            expoConfig.staking_contract,
                            "9999999999999999999999999999999999999999999999"
                          )
                        }
                      >
                        APPROVE{" "}
                      </button>
                    </div>
                  )}
                </div>
                {isValid ? (
                  <Alert variant="danger" fade="1">
                    {stakestate.errorMessage}
                  </Alert>
                ) : (
                  <></>
                )}
              </form>
            </Tab.Pane>
            <Tab.Pane eventKey="second" className="tabPaneWrapper">
              <div className="stakeCardWrapper">
                <div className="stakeCardWrapper-left">
                  <Row className="headerRow">
                    <Col>AMOUNT</Col>
                    <Col>EXPO</Col>
                  </Row>

                  <Row className="secondRow">
                    <Col className="firstCol">
                      <input
                        className="input-field"
                        type="number"
                        value={inputUnstakeNum}
                        onChange={handleUnstakeInputChange}
                        placeholder="0.00"
                      />
                    </Col>
                    <Col>
                      <button
                        onClick={handleMaxUnstakeInput}
                        className="max-btn"
                      >
                        {" "}
                        MAX{" "}
                      </button>
                    </Col>
                  </Row>
                </div>

                <div className="stakeCardWrapper-right">
                  <button
                    className="btns"
                    onClick={() => handleunstake(inputUnstakeNum)}
                  >
                    UNSTAKE{" "}
                  </button>
                </div>
              </div>
              {isValid ? (
                <Alert variant="danger" fade="1">
                  {unstakestate.errorMessage}
                </Alert>
              ) : (
                <></>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default Staking;
