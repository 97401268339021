import React from "react";
import "../../styles/portfolioBorder.scss";

const PortfolioBorder = () => {
  return (
    // <div className="absolute w-[90%] h-[90%] left-[5%] top-[5%]">
    <div className="portfoliostatborderWrap">
      {/* LEFT LONG LINE */}
      <div className={"leftLong borderLine"}></div>

      {/* TOP LONG LINE */}
      <div className={"topLong borderLine"}></div>

      {/* RIGHT LONG LINE */}
      <div className={"rightLong borderLine"}></div>

      {/* BOTTOM LONG LINE */}
      <div className={"bottomLong borderLine"}></div>

      {/* TOP RIGHT SHORT ROTATED LINE */}
      <div className={"toprightShort borderLine"}></div>

      {/* BOTTOM left SHORT ROTATED LINE */}
      <div className={"bottomleftShort borderLine"}></div>

      {/* TOp RIGHT ELEMENT */}
      <div className={"toprightElement1 borderLine"}></div>
      <div className={"toprightElement2 borderLine"}></div>
      <div className={"toprightElement3 borderLine"}></div>
    </div>
    // </div>
  );
};

export default PortfolioBorder;
