import React from "react";
import CalcStatBorder from "./CalcStatBorder";
import "./calcStatChart.scss"

const CalcStatChart = ({ children }) => {
  return (
    <div className="calcStatChart">
      <CalcStatBorder />
      <div className="calcChartInfo">{children}</div>
    </div>
  );
};

export default CalcStatChart;
