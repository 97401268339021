import React, { useState, useEffect } from "react";
/* import StatChart from "../dashboard/StatChart"; */
import Chart from "react-apexcharts";
import "./supplyMechanics.scss";
import { Col, Row } from "react-bootstrap";
import nFormatter from "../../hooks/useNumberFormatter";
import { GettotalDeposited } from "../../hooks/usedapphooks";

const walleturl =
  "https://openapi.debank.com/v1/user/token_list?id=0x9e2f500a31f5b6ec0bdfc87957587307d247a595&is_all=true";
const walleturl_degen =
  "https://openapi.debank.com/v1/user/token_list?id=0x880a3769d82d63ee014195b758854d5750bb30ca&is_all=true";
const total_balance_url =
  "https://openapi.debank.com/v1/user/total_balance?id=0x9e2f500a31f5b6ec0bdfc87957587307d247a595";
const total_balance_url_degen =
  "https://openapi.debank.com/v1/user/total_balance?id=0x880a3769d82d63ee014195b758854d5750bb30ca";
const totalSupply = 10 ** 12;

const SupplyMechanics = () => {
  const [expoBalance, setExpoBalance] = useState(0);
  const [expoBalance_degen, setExpoBalance_degen] = useState(0);
  const totalBurned = 183277026497;
  const totalDeposited = GettotalDeposited() * 0.000000000000000001;

  useEffect(() => {
    let mounted = true;
    function groupBy(arrayObjects, key) {
      return arrayObjects.reduce(function (result, currentObject) {
        const val = currentObject[key];
        result[val] = result[val] || [];
        result["all"] = result["all"] || [];

        if (currentObject["price"] > 0 && currentObject["amount"] > 0) {
          result[val].push(currentObject);
          result["all"].push(currentObject);
        }
        return result;
      }, {});
    }
    function getnonverifiedusd(answer) {
      var expo_usd = 0;

      for (let i = 0; i < answer["all"].length; i++) {
        if (answer["all"][i]["is_verified"] === false) {
          expo_usd =
            expo_usd + answer["all"][i]["price"] * answer["all"][i]["amount"];
        }
      }
      return expo_usd;
    }

    function getexpoBalance(data) {
      for (let i = 0; i < data["all"].length; i++) {
        if (data["all"][i]["optimized_symbol"] === "EXPO") {
          var expo_bal = data["all"][i]["amount"];
          break;
        }
      }
      return expo_bal;
    }

    const getExpo = async () => {
      const res = await fetch(walleturl);
      const data = await res.json();
      // const answer = await getexpousd(groupBy(data, "chain"));
      const answer2 = await getexpoBalance(groupBy(data, "chain"));
      if (answer2) {
        setExpoBalance(answer2);
      }
    };
    const getExpo_degen = async () => {
      const res = await fetch(walleturl_degen);
      const data = await res.json();
      // const answer = await getexpousd(groupBy(data, "chain"));
      const answer2 = await getexpoBalance(groupBy(data, "chain"));
      if (answer2) {
        setExpoBalance_degen(answer2);
      }
    };

    if (mounted) {
      // getAnswer();
      // getAnswer_degen();
      getExpo();
      getExpo_degen();
    }
    return () => (mounted = false);
  }, []);

  // PIE CHART APEX
  const pie_data = [
    totalSupply - totalBurned - expoBalance - expoBalance_degen,
    totalBurned,
    expoBalance + expoBalance_degen,
  ];

  const pie_options = {
    stroke: {
      colors: ["#e1227c"], // border of pie color
    },
    chart: {
      width: 300,
      type: "donut",
      fontFamily: "PPFraktionMono-Regular",
    },
    colors: ["#e1227c", "#e27aa9", "#e5e5e5"],
    labels: ["CIRCULATING", "BURNED", "TREASURY"],
    responsive: [
      {
        breakpoint: 1000,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            show: false,
            position: "right",
          },
        },
      },
    ],

    plotOptions: {
      pie: {
        dataLabels: {
          offset: 0,
        },
      },
    },
    title: {
      text: undefined,
    },
    dataLabels: {
      // formatter(val, opts) {
      //   const name = opts.w.globals.labels[opts.seriesIndex];
      //   return [name, val.toFixed(1) + "%"];
      // },
      show: false,
    },
    legend: {
      show: false,
      // fontSize: "0.5vh",
      position: "right",
      horizontalAlign: "center",
      onItemHover: {
        highlightDataSeries: true,
      },
      labels: {
        colors: "white",
        useSeriesColors: false,
      },
    },
    tooltip: {
      onDatasetHover: {
        highlightDataSeries: true,
      },
    },
  };

  return (
    <div className="supplyContainer">
      <div className="supplyContainer-left">
        <Chart
          series={pie_data}
          options={pie_options}
          type="donut"
          // height="350px"
          // width="300px"
        />

        {/*        <div className="supplyDetails"> */}
        <ul className="labelDetails">
          <li>
            <span className="logospan">
              {" "}
              <img src="/images/supply.svg" alt="label" />
            </span>
            <span> Circulating </span>
          </li>

          <li>
            <span className="logospan">
              {" "}
              <img src="/images/burned.svg" alt="label" />
            </span>
            <span> Burned </span>
          </li>

          <li>
            <span className="logospan">
              {" "}
              <img src="/images/treasurySupply.svg" alt="label" />
            </span>
            <span> Treasury </span>
          </li>
        </ul>
      </div>
      <div className="supplyContainer-right">
        <Row className="headerRow">
          <Col>Order</Col>
          <Col>Supply</Col>
        </Row>

        {/* <div className="mechanicsvalue"> */}
        <Row className="firstRow">
          <Row>
            <Col className="firstCol">Circulation </Col>
            <Col>
              {nFormatter(
                totalSupply - totalBurned - expoBalance - expoBalance_degen,
                0
              )}
            </Col>
          </Row>
          <Row>
            <Col className="firstCol">Treasury </Col>
            <Col>{nFormatter(expoBalance + expoBalance_degen, 0)} </Col>
          </Row>
          <Row>
            <Col className="firstCol">Burned </Col>
            <Col>{nFormatter(totalBurned, 0)} </Col>
          </Row>
          <Row>
            <Col className="firstCol">Staked </Col>
            <Col>{nFormatter(totalDeposited, 0)} </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
};

export default SupplyMechanics;
