import React from "react";
import  "../../styles/calcStatBorder.scss";

const CalcStatBorder = () => {
  return (
    // <div className="absolute w-[90%] h-[90%] left-[5%] top-[5%]">
    <div className="calcstatborderWrap">
      {/* LEFT LONG LINE */}
      <div className={"leftLong borderLine"}></div>

      {/* TOP LONG LINE */}
      <div className={"topLong borderLine"}></div>

      {/* RIGHT LONG LINE */}
      <div className={"rightLong borderLine"}></div>

      {/* BOTTOM LONG LINE */}
      <div className={"bottomLong borderLine"}></div>

      {/* TOP RIGHT SHORT ROTATED LINE */}
     {/*  <div className={"toprightShort borderLine"}></div> */}

      {/* BOTTOM RIGHT SHORT ROTATED LINE */}
      <div className={"bottomrightShort borderLine"}></div>
    </div>
    // </div>
  );
};

export default CalcStatBorder;
