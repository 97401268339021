import React, { useState, useEffect } from "react";
import Stat from "./Stat";
import "../../styles/dashboard/stats.scss";
import { Row, Col } from "react-bootstrap";
import { expoConfig } from "../../config";
import nFormatter from "../../hooks/useNumberFormatter";
import { getBalance, getHolders } from "../../hooks/useCovalent";

const coin_address = expoConfig.smartContract;
const ether_pair = expoConfig.liquidity_pool_pair_uniswap;

const walleturl =
  "https://openapi.debank.com/v1/user/token_list?id=0x9e2f500a31f5b6ec0bdfc87957587307d247a595&is_all=true";
const walleturl_degen =
  "https://openapi.debank.com/v1/user/token_list?id=0x880a3769d82d63ee014195b758854d5750bb30ca&is_all=true";

const totalSupply = 10 ** 12;

const Stats = ({chainId}) => {
  const [price, setPrice] = useState(0);
  const [etherHolders, setEtherHolder] = useState(0);
  const [uniliquidity, setUniLiquidity] = useState(0);

  const [expoBalance, setExpoBalance] = useState(0);
  const [expoBalance_degen, setExpoBalance_degen] = useState(0);

  const totalBurned = 183277026497;

  useEffect(() => {
    let mounted = true;
    function groupBy(arrayObjects, key) {
      return arrayObjects.reduce(function (result, currentObject) {
        const val = currentObject[key];
        result[val] = result[val] || [];
        result["all"] = result["all"] || [];

        if (currentObject["price"] > 0 && currentObject["amount"] > 0) {
          result[val].push(currentObject);
          result["all"].push(currentObject);
        }
        return result;
      }, {});
    }
    function getexpoBalance(data) {
      for (let i = 0; i < data["all"].length; i++) {
        if (data["all"][i]["optimized_symbol"] === "EXPO") {
          var expo_bal = data["all"][i]["amount"];
          break;
        }
      }
      return expo_bal;
    }
    const getExpo = async () => {
      const res = await fetch(walleturl);
      const data = await res.json();
      const answer = await getexpoBalance(groupBy(data, "chain"));
      if (answer) {
        setExpoBalance(answer);
      }
    };
    const getExpo_degen = async () => {
      const res = await fetch(walleturl_degen);
      const data = await res.json();
      const answer = await getexpoBalance(groupBy(data, "chain"));
      if (answer) {
        setExpoBalance_degen(answer);
      }
    };

    if (mounted) {
      getExpo();
      getExpo_degen();
    }
    getBalance(chainId, ether_pair).then((items) => {
      if (mounted) {
        var balance = 0;
        try {
          for (let i = 0; i < items["data"]["items"].length; i++) {
            if (items["data"]["items"][i]["contract_name"]) {
              var item_price = items["data"]["items"][i]["quote_rate"];
              var item_balance =
                items["data"]["items"][i]["balance"] /
                10 ** items["data"]["items"][i]["contract_decimals"];
              balance = balance + item_balance * item_price;
            }
            if (
              items["data"]["items"][i]["contract_address"] ===
              "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
              // This is WETH contract id
            ) {
              var weth_price = items["data"]["items"][i]["quote_rate"];
              var weth_balance =
                items["data"]["items"][i]["balance"] /
                10 ** items["data"]["items"][i]["contract_decimals"];
            }
            if (
              items["data"]["items"][i]["contract_address"] ===
              coin_address.toLowerCase()
            ) {
              var coin_balance =
                items["data"]["items"][i]["balance"] /
                10 ** items["data"]["items"][i]["contract_decimals"];
              // var coin_price = items["data"]["items"][i]["quote_rate"];
            }
          }

          setPrice(
            (Number(weth_price) * Number(weth_balance)) / Number(coin_balance)
          );
          setUniLiquidity(
            2 * (Number(weth_price) * Number(weth_balance)).toFixed(0)
          );
        } catch {
          setPrice(0);
          setUniLiquidity(0);
        }
      }
    });
    getHolders("1", coin_address, "").then((items) => {
      if (mounted) {
        try {
          setEtherHolder(items["data"]["items"].length);
        } catch (ex) {
          console.log(ex);
          setEtherHolder(0);
        }
      }
    });

    return () => (mounted = false);
  }, []);

  const stats = [
    {
      name: "Market Cap",
      stat:
        "$ " +
        nFormatter(
          (totalSupply - totalBurned - expoBalance - expoBalance_degen) * price,
          0
        ),
    },
    {
      name: "Price",
      stat: "$ " + nFormatter(price, 9),
    },
    {
      name: "Liquidity",
      stat: "$ " + nFormatter(uniliquidity, 0),
    },
    {
      name: "Holders",
      stat: etherHolders,
    },
  ];

  return (
    <div className="statsWrap">
      <Row>
        {stats.map((item, i) => {
          return (
            <Col md="6" xl key={i}>
              <div>
                <Stat name={item.name} stat={item.stat} />
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Stats;
