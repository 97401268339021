import React, { useState, useEffect } from "react";
import "../../styles/connectbtn.scss";
// import { Modal, Button,  } from "react-bootstrap";
// import styled from "@emotion/styled";
import { useEthers, useEtherBalance } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { Modal, Button, Dropdown } from "react-bootstrap";

const MyVerticallyCenteredModal = (props) => {
  const { activateBrowserWallet, activate } = useEthers();
  const [activateError, setActivateError] = useState("");
  const { error } = useEthers();
  const RPC_URLS = {
    // 1: "https://mainnet.infura.io/v3/d5407876688e443aa25d6497fd4a1c46",
    1: "https://mainnet.infura.io/v3/d5407876688e443aa25d6497fd4a1c46",
    4: "https://rinkeby.infura.io/v3/55d040fb60064deaa7acc8e320d99bd4",
  };

  useEffect(() => {
    if (error) {
      setActivateError(error.message);
    }
  }, [error]);

  const walletconnect = new WalletConnectConnector({
    rpc: {
      1: RPC_URLS[1],
      4: RPC_URLS[4],
    },
    qrcode: true,
    pollingInterval: 30000,
  });

  const walletlink = new WalletLinkConnector({
    url: RPC_URLS[1],
    appName: "Exponential Capital",
    supportedChainIds: [1],
  });

  const handleConnectWallet = async () => {
    activateBrowserWallet();
    setActivateError(false);
  };
  const handleWalletlink = async () => {
    activate(walletlink, undefined, true);
  };
  const handleWalletConnect = async () => {
    activate(walletconnect, undefined, true);
  };

  // const ErrorWrapper = styled.div`
  //   color: #ff3960;
  //   margin-right: 40px;
  //   margin-left: 40px;
  //   margin-top: 10px;
  //   overflow: auto;
  // `;

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Connect Wallet
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center m-3">
        <p className="mb-0">
          By connecting a wallet, you agree to Expo's Terms of Service.
        </p>
        <p></p>
        <div className="row">
          <div className="col-sm-12 btn-items">
            <Button
              bsPrefix="custom-btn"
              className="btn-lg mx-2 connectBtn"
              onClick={handleConnectWallet}
            >
              <img
                className="mr-2 px-2"
                src="/images/crypto/metamask.png"
                alt="Connect Metamask"
                width="40px"
              />
              Metamask
            </Button>

            <Button
              bsPrefix="custom-btn"
              className="btn-lg mx-2 connectBtn"
              onClick={handleWalletlink}
            >
              <img
                className="mr-2 px-2"
                src="/images/crypto/coinbaseWalletIcon.svg"
                alt="Connect Wallet Link"
              />
              Coinbase
            </Button>

            <Button
              bsPrefix="custom-btn"
              className="btn-lg mx-2 connectBtn"
              onClick={handleWalletConnect}
            >
              <img
                className="mr-2 px-2"
                src="/images/crypto/walletConnectIcon.svg"
                alt="Connect Wallet Connect"
              />
              WalletConnect
            </Button>
          </div>
        </div>
        <div>{activateError}</div>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
const ConnectBtn = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const { account, deactivate } = useEthers();
  function handleDeactivateAccount() {
    deactivate();
    setModalShow(false);
  }
  const etherBalance = useEtherBalance(account);

  return account ? (
    <Dropdown>
      <Dropdown.Toggle as="a">
        <Button
          bsPrefix="custom-btn"
          className="connectBtn"
          // style={{ backgroundColor: "#161122" }}
          _hover={{
            borderColor: "#161122",
            color: "#161122",
          }}
          _active={{
            backgroundColor: "#e1227c",
            borderColor: "#e1227c",
          }}
        >
          {/* <Identicon /> */}
          {account &&
            `${account.slice(0, 8)}...${account.slice(
              account.length - 4,
              account.length
            )}`}
        </Button>
      </Dropdown.Toggle>

      <Dropdown.Menu drop="end">
        <Dropdown.Item>
          {/* <FontAwesomeIcon icon={faWallet} className="align-middle me-2" /> */}
          Balance:
          <span className="">
            {" "}
            {etherBalance &&
              parseFloat(formatEther(etherBalance)).toFixed(3)}{" "}
            ETH
          </span>
        </Dropdown.Item>
        <Dropdown.Item
          href={`https://etherscan.io/address/${account}`}
          target="_blank"
          rel="noreferrer"
          color="gray.400"
        >
          {/* <ExternalLink size={15} className="me-2" /> */}
          View on Explorer
        </Dropdown.Item>

        <Dropdown.Item onClick={handleDeactivateAccount}>
          {/* <LogOut size={15} className="me-2" /> */}
          Disconnect
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <div className="connectBtn">
      <div onClick={() => setModalShow(true)} style={{ textAlign: "center" }}>
        Connect Wallet
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default ConnectBtn;
