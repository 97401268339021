import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../styles/dashboard/statcharts.scss";
import Portfolio from "../../pages/Portfolio";

import {
  // useTotalDistributed,
  useTotalDividendDistributed,
  useTotalDividendsClaimed,
} from "../../hooks/usedapphooks";
import { useEthers } from "@usedapp/core";
import { expoConfig } from "../../config";
import nFormatter from "../../hooks/useNumberFormatter";
// import { getBalance } from "../../hooks/useCovalent";
import Stat from "./Stat";

// const liquidity_wallet = expoConfig.liquidity_wallet;
// const marketing_wallet = expoConfig.marketing_wallet;

const StatCharts = () => {
  const totalDistributed = useTotalDividendDistributed();
  // const oldDistributed = useTotalDistributed();
  const totalclaimed = useTotalDividendsClaimed();

  return (
    <div className="statcharts">
      <Row>
        <Col
          xl={{ span: 7 }}
          lg={{ span: 7 }}
          md={{ span: 12 }}
          sm={{ span: 12 }}
        >
          <Portfolio />
        </Col>
        <Col
          className="statCol"
          xl={{ span: 5 }}
          lg={{ span: 5 }}
          md={{ span: 12 }}
          sm={{ span: 12 }}
        >
          <div className="portfolio-container-two">
            <div className="unclaimed">
              <Stat
                name="Total Unclaimed Rewards"
                stat={
                  totalclaimed
                    ? nFormatter(
                        totalDistributed * 0.000000000000000001 -
                          totalclaimed * 0.000000000000000001,
                        2
                      ) + " ETH"
                    : "No Data"
                }
              />
            </div>
            <div className="reflections">
              <Stat
                name="Total Distributed Rewards"
                stat={
                  totalDistributed
                    ? nFormatter(
                        totalDistributed * 0.000000000000000001 +
                          // oldDistributed * 10 ** -18,
                          152.18,
                        2
                      ) + " ETH"
                    : "No Data"
                }
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default StatCharts;

/* 
<StatChart>
            <div className="chartinfoName">Wallet Balance</div>
            
            <div className="chartinfoChart">
              <Chart
                series={bar_data}
                options={bar_options}
                type="bar"
                height="350"
              />
            </div>
          </StatChart>
*/
