import React from "react";
/* import { Row, Col } from "react-bootstrap"; */
/* import "../styles/dashboard/portfolio.scss"; */
/* import Stat from "../components/dashboard/Stat";
import {
  useCheckDividendPending,
  useClaimv2,
  // useCompoundv2,
  useMyClaimedDividends,
  useTotalDividendDistributed,
  useTotalDividendsClaimed,
  useGetBalance,
} from "../hooks/usedapphooks"; */
/* import { useEthers } from "@usedapp/core";
import { useCoingeckoTokenPrice } from "@usedapp/coingecko";
import { expoConfig } from "../config";
import nFormatter from "../hooks/useNumberFormatter"; */
import PortfolioStatCharts from "../components/portfolio/PortfolioStatCharts";
import PortfolioStatChartsArb from "../components/portfolio/PortfolioStatChartsarb";
import PortfolioTwoCharts from "../components/portfolio2/PortfolioTwoCharts";

const Portfolioarb = () => {
  /*  const { send: claimreflection } = useClaimv2(); */
  // const { send: compoundReflection } = useCompoundv2();
  /*  const { account } = useEthers();
  const mybalance = useGetBalance(account);
  const eth_contract = expoConfig.ethereumContract;
  const myclaimed = useMyClaimedDividends(account);
  const eth_price = useCoingeckoTokenPrice(eth_contract, "usd");
  const pendingRewards = useCheckDividendPending(account);
  const totalDistributed = useTotalDividendDistributed();
  const totalclaimed = useTotalDividendsClaimed();

  function handleClaim() {
    claimreflection();
  }

  const ClaimEth = () => {
    return (
      <div className={"claimeth"} onClick={handleClaim}>
        Claim as ETH
      </div>
    );
  }; */

  return (
    <div className="portfolioWrap">
      <div className="portfolioStatsWrapper">
        <PortfolioTwoCharts />
        <PortfolioStatChartsArb />
        
      </div>
      {/*  <h2 class="portfolioWrapTitle"> Portfolio </h2>
      <Row>
        <Col md={{ span: 4 }} sm={{ span: 2 }} gap={2}></Col>
        <Col md={{ span: 4 }} sm={{ span: 8 }}>
          <Stat
            name="My Balance"
            stat={
              mybalance
                ? nFormatter(mybalance * 0.000000000000000001, 2) + " EXPO"
                : "No Data"
            }
          />
        </Col>
        <Col md={{ span: 4 }} sm={{ span: 2 }}></Col>
      </Row>

      <Row className="rowMid">
        <Col md={{ span: 5 }} sm={{ span: 6 }}>
          <Stat
            name="My Pending Rewards"
            stat={
              pendingRewards
                ? nFormatter(pendingRewards * 0.000000000000000001, 4) + " ETH"
                : "No Data"
            }
            funcBtn={<ClaimEth />}
          />
        </Col>
        <Col md={{ span: 2 }} sm={{ span: 0 }}></Col>

        <Col md={{ span: 5 }} sm={{ span: 6 }}>
          <Stat
            name="My Claimed Rewards"
            stat={
              myclaimed
                ? nFormatter(myclaimed * 0.000000000000000001, 2) + " ETH"
                : "No Data"
            }
          />
        </Col>
      </Row>

      <Row>
        <Col md={{ span: 5 }} sm={{ span: 6 }}>
          <Stat
            name="Total Unclaimed Rewards"
            stat={
              totalclaimed
                ? nFormatter(
                    totalDistributed * 0.000000000000000001 -
                      totalclaimed * 0.000000000000000001,
                    2
                  ) + " ETH"
                : "No Data"
            }
          />
        </Col>
        <Col md={{ span: 2 }} sm={{ span: 0 }} className="colHide"></Col>

        <Col md={{ span: 5 }} sm={{ span: 6 }}>
          <Stat
            name="Total Reflections Distributed"
            stat={
              totalDistributed
                ? nFormatter(totalDistributed * 0.000000000000000001, 5) +
                  " ETH"
                : "No Data"
            }
          />
        </Col>
      </Row> */}
    </div>
  );
};
export default Portfolioarb;
