import React from "react";
import { Button, Row } from "react-bootstrap";
import "../../styles/alpha/alpha.scss";

const AfterForm = () => {
  const handleBack = () => {
    window.location.reload();
  };
  return (
    <div>
      <div
        style={{ textAlign: "center", color: "#e1227c" }}
        className="thanksAlpha"
      >
        Thanks for your alpha
      </div>
      <div
        className="submitWrap"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "1.2rem",
        }}
      >
        <Button onClick={handleBack} className="submitBtn">
          Back to Portal
        </Button>
      </div>
    </div>
  );
};

export default AfterForm;
