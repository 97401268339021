import React, { Component } from "react";
import Home from "../../Assets/image/HOME.png";
import Menu from "../../Assets/image/Menu1.png";
import MENU from "../../Assets/image/MENU.png";
import up from "../../Assets/image/up.png";
import Down from "../../Assets/image/Down.png";
import "../../styles/Layout.scss";
const BgLayout = ({ onClick, Side }) => {
  return (
    <>
      {/* <img src={Home} className="home-img mt-2" /> */}
      {/* <div className="home-img mt-2"> HOME </div> */}
      <div className="menu-div">
        <button className="btn-menu" onClick={onClick}>
          <img src={Menu} className="menu-img" />
        </button>
        <button className="btn-menu" onClick={onClick}>
          <img src={Side ? up : Down} className="menu-text" />
          <div className="menu-text"> MENU </div>
          {/* <img src={MENU} className="menu-text" /> */}
        </button>
        {Side ? (
          <></>
        ) : (
          <button className="btn-menu fake-button" onClick={onClick}></button>
        )}
      </div>
    </>
  );
};

export default BgLayout;
