import React from 'react'
import "../../styles/portfolioBorderTwo.scss"

const PortfolioBorder2 = () => {
  return (
     // <div className="absolute w-[90%] h-[90%] left-[5%] top-[5%]">
     <div className="portfolioTwostatborderWrap">
     {/* LEFT LONG LINE */}
     <div className={"leftLong borderLine"}></div>

     {/* TOP LONG LINE */}
     <div className={"topLong borderLine"}></div>

     {/* RIGHT LONG LINE */}
     <div className={"rightLong borderLine"}></div>

     {/* BOTTOM LONG LINE */}
   {/*   <div className={"bottomLong borderLine"}></div> */}

     {/* TOP RIGHT SHORT ROTATED LINE */}
{/*      <div className={"toprightShort borderLine"}></div> */}

     {/* BOTTOM left SHORT ROTATED LINE */}
     {/* <div className={"bottomleftShort borderLine"}></div> */}
   </div>
   // </div>
  )
}

export default PortfolioBorder2