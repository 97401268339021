import React, { useState } from "react";
import "../styles/landingpages.scss";
import Layout from "./DashboardWrapper";
import { Outlet } from "react-router";
import LandingSideBar from "./SidebarLanding";
import SCROLLER from "../Assets/image/SCROLLER.png";
import RULER from "../Assets/image/RULER.png";
import BgLayout from "../components/landing/BgLayout";
import "../styles/Layout.scss";
const Landing = () => {
  const [Side, setSide] = useState(true);

  return (
    <Layout>
      <div className="main-bg">
        <div className="grid-bg">
          <div className="border-bg">
            <BgLayout
              Side={Side}
              onClick={() => {
                setSide(!Side);
              }}
            />
            <div className="main-body">
              <div className="sidebar">
                <LandingSideBar Side={Side} setClose={setSide} />
              </div>
            </div>
            <div className={`outletDiv ${Side ? "" : "isBlurred"} `}>
              <Outlet />
            </div>
            <div className="bottomwrap">
              {/* <img src={SCROLLER} width="25px" />
              <p className="Scroll">SCROLL</p> */}
              <img src={RULER} width="60%" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

{
  /* <div className={`outletDiv ${close ? "" : "isBlurred"}`}>
          <Outlet />
        </div> */
}

export default Landing;
