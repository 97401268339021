import React from "react";
import PortFolioTwoStat from "./PortfolioTwoStat";
import "./portfolioTwoCharts.scss";

const PortfolioTwoCharts = () => {
  return (
    <div className="portfolioTwoChartsContainer">
      <PortFolioTwoStat className="portfolioTwo">
        <h4> Portfolio </h4>
      </PortFolioTwoStat>
    </div>
  );
};

export default PortfolioTwoCharts;
