import React from "react";
import { Stack } from "react-bootstrap";
import "../../styles/dashboard/stat.scss";

const Stat = ({ name, stat, funcBtn }) => {
  return (
    <div className="statWrap">
      <Stack direction="vertical" gap={2}>
        <div className="statName">{name}</div>
        <div className="statFigure">{stat}</div>

        {!!funcBtn && <div className="funcBtn">{funcBtn}</div>}
      </Stack>
    </div>
  );
};

export default Stat;
