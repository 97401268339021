// hooks/index.ts
import { ethers } from "ethers";
import { Contract } from "@ethersproject/contracts";
import {
  useContractFunction,
  useContractCall,
  useSendTransaction,
} from "@usedapp/core";
import DividendAbi from "../abi/expo_dividendarb.json";
import NewAbi from "../abi/expo_new.json";
import OldAbi from "../abi/expo.json";
import StakingAbi from "../abi/expo_compound_abi.json";
import { expoConfig, oldexpo } from "../config";

const simpleContractAddress = expoConfig.dividend_contract_arb;
const dividendDistributorInterface = new ethers.utils.Interface(DividendAbi);

const coinContractAddress = expoConfig.smartContractarb;
const coinInterface = new ethers.utils.Interface(NewAbi);
const claimcontract = new Contract(coinContractAddress, coinInterface);

const stakingContractAddress = expoConfig.staking_contract;
const stakingInterface = new ethers.utils.Interface(StakingAbi);
const stakecontract = new Contract(stakingContractAddress, stakingInterface);

export function useStake() {
  const { state, send, resetstate } = useContractFunction(
    stakecontract,
    "deposit",
    {}
  );
  return { state, send, resetstate };
  // send({ value: amount });
}

export function useApprove() {
  const { state, send, resetstate } = useContractFunction(
    claimcontract,
    "approve",
    {}
  );
  return { state, send, resetstate };
}
export function useUnstake() {
  const { state, send, resetstate } = useContractFunction(
    stakecontract,
    "withdraw",
    {}
  );
  return { state, send, resetstate };
  // send({ value: amount });
}

export function GetUserInfo(address) {
  const [pending] =
    useContractCall(
      address && {
        abi: stakingInterface,
        address: stakingContractAddress,
        method: "userInfo",
        args: [address],
      }
    ) ?? [];
  return pending;
}
export function GetIsAuthorized(address) {
  const [pending] =
    useContractCall(
      address && {
        abi: stakingInterface,
        address: stakingContractAddress,
        method: "isAuthorized",
        args: [address],
      }
    ) ?? [];
  return pending;
}
export function GetStakedBalance(address) {
  const [pending] =
    useContractCall(
      address && {
        abi: stakingInterface,
        address: stakingContractAddress,
        method: "balance",
        args: [address],
      }
    ) ?? [];
  return pending;
}
export function GetStakedAPY() {
  const [pending] =
    useContractCall({
      abi: stakingInterface,
      address: stakingContractAddress,
      method: "getPlatformAPY",
      args: [],
    }) ?? [];
  return pending;
}

export function GettotalDeposited() {
  const [totalDist] =
    useContractCall({
      abi: stakingInterface,
      address: stakingContractAddress,
      method: "totalDeposited",
      args: [],
    }) ?? [];
  return totalDist;
}

export function useClaimv2() {
  const { state, send } = useContractFunction(claimcontract, "claim", {});
  return { state, send };
}

export function useCompoundv2() {
  const { state, send } = useContractFunction(claimcontract, "compound", {});
  return { state, send };
}

export function useGetBalance(address) {
  const [pending] =
    useContractCall(
      address && {
        abi: dividendDistributorInterface,
        address: simpleContractAddress,
        method: "balanceOf",
        args: [address],
      }
    ) ?? [];
  return pending;
}
// Here address is the account address of the person on the website
// Simple contract address is the address of the treasury

export function useCheckDividendPending(address) {
  const [pending] =
    useContractCall(
      address && {
        abi: dividendDistributorInterface,
        address: simpleContractAddress,
        method: "withdrawableRewardOf",
        args: [address],
      }
    ) ?? [];
  return pending;
}

export function useTotalDividendDistributed() {
  const [totalDist] =
    useContractCall({
      abi: dividendDistributorInterface,
      address: simpleContractAddress,
      method: "totalRewardsDistributed",
      args: [],
    }) ?? [];
  return totalDist;
}

export function useTotalDividendsClaimed() {
  const [totalDist] =
    useContractCall({
      abi: dividendDistributorInterface,
      address: simpleContractAddress,
      method: "totalRewardsWithdrawn",
      args: [],
    }) ?? [];
  return totalDist;
}
export function useMyClaimedDividends(address) {
  const [totalWalletRewards] =
    useContractCall({
      abi: dividendDistributorInterface,
      address: simpleContractAddress,
      method: "withdrawnRewardOf",
      args: [address],
    }) ?? [];
  return totalWalletRewards;
}

// OLD SMART CONTRACT STUFFF

const oldContractAddress = oldexpo.smartContract;
const oldDistributorInterface = new ethers.utils.Interface(OldAbi);

export function useTotalDistributed() {
  const [totalDist] =
    useContractCall({
      abi: oldDistributorInterface,
      address: oldContractAddress,
      method: "totalRewardsDistributed",
      args: [],
    }) ?? [];
  return totalDist;
}
