import React from "react";
import ConnectBtn from "../dashboard/ConnectBtn";
import { Row, Col } from "react-bootstrap";
import "../../styles/alpha/alpha.scss";

const ConnectToShowForm = () => {
  return (
    <div className="connectoshow">
      <p
        style={{
          color: "#e1227c",
          textAlign: "center",
          fontSize: "1.2rem",
          marginBottom: "2rem",
        }}
      >
        Connect to a wallet to continue
      </p>
      <Row>
        <Col></Col>
        <Col>
          <ConnectBtn />
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
};

export default ConnectToShowForm;
