import React, { useRef, useState } from "react";
import Main from "../Main";
import SCROLLER from "../../Assets/image/SCROLLER.png";
import RULER from "../../Assets/image/RULER.png";
import { NavLink, useLocation } from "react-router-dom";
import { exit } from "process";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";

const pages = ["/", "/about-expo", "about-faas", "roadmap", "faqs"];
// const pages = ["/"];
const Heropage = ({ onClick }) => {
  const navigation = useNavigate();
  const touchStartY = useRef(0);
  const touchEndY = useRef(0);

  function handleStartGesture(e) {
    touchStartY.current = e.changedTouches[0].screenY;
  }
  function handleViewChange(e) {
    if (e.deltaY > 30) {
      // window.location.href = pages[2];
      setTimeout(() => {
        // navigation(pages[1], { replace: false });
      }, 500);
    }
  }

  function handleEndGesture(e) {
    touchEndY.current = e.changedTouches[0].screenY;

    if (touchEndY.current - touchStartY.current < -30) {
      setTimeout(() => {
        navigation(pages[1], { replace: false });
      }, 500);
    }
  }
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.5 }}
      className="mainWrap"
      onWheel={handleViewChange}
      onTouchStart={handleStartGesture}
      onTouchEnd={handleEndGesture}
      onClick={onClick}
    >
      <div className="heading">HOME</div>
      <div className="topWrap">
        <Main />
      </div>
      {/* <div className="bottom">
        <img src={SCROLLER} width="25px" />
        <p className="Scroll">SCROLL</p>
        {/* <img src={RULER} width="60%" /> 
      </div> */}
      {/* <NavLink className="next_button" to={pages[1]}></NavLink> */}
    </motion.div>
  );
};

export default Heropage;
