import React from "react";
import CalcStatChart from "./CalcStatChart";
import "../../styles/calcStatCharts.scss";
import nFormatter from "../../hooks/useNumberFormatter";

const CalcStatCharts = (share, volume, dividend) => {
  return (
    <CalcStatChart>
      <div className="calcChartsContainer">
        <div className="chartsSubContainer one">
          <h5> Annual Return </h5>
          <h3>
            ${" "}
            {share
              ? nFormatter(
                  share * dividend * 12 + share * volume * 0.025 * 365,
                  4
                )
              : 0}
          </h3>
        </div>

        <div className="calcBorder"></div>

        <div className="chartsSubContainer two">
          <h5> Reflection Tax (Daily) </h5>
          <h3> $ {share ? nFormatter(share * volume * 0.025, 4) : 0} </h3>
        </div>

        <div className="calcBorder"></div>

        <div className="chartsSubContainer three">
          <h5> Farm Reward (Monthly) </h5>
          <h3> $ {share ? nFormatter(share * dividend, 4) : 0} </h3>
        </div>
      </div>
    </CalcStatChart>
  );
};

export default CalcStatCharts;
