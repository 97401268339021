import {
  MultifarmProvider,
  Dashboard,
  DashboardTabs,
  DASHBOARD_TABS_VARIANTS,
} from "@multifarm/widget";
import { useState } from "react";
import { multifarmConfig } from "../config";
import { defaultTheme, extendTheme } from "@multifarm/widget";

const expoChartColors = ["#FFB4D7", "#F56FAE", "#DE2179"];

const expoTheme = extendTheme(defaultTheme, {
  text: {
    primary: "#FFFFFF",
    secondary: "#E5E5E5",
    highlight: "#DE2179",
  },
  checkbox: {
    background: {
      primary: "#DE2179",
    },
    text: {
      primary: "#FFFFFF",
    },
  },
  border: {
    primary: "#DE2179",
  },
  card: {
    background: {
      // primary: "#161121",
      primary: "transparent",
    },
  },
  tabs: {
    background: {
      primary: "#DE2179",
      secondary: "#161121",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#FFFFFF",
    },
    border: {
      primary: "#DE2179",
    },
  },
  table: {
    background: {
      primary: "#161121",
    },
  },
  list: {
    background: {
      primary: "#161121",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#FFFFFF",
      highlight: "#DE2179",
    },
  },
  progress: {
    background: {
      primary: "#DE2179",
      secondary: "rgba(255, 255, 255, 0.15)",
    },
  },
  form: {
    background: {
      primary: "#DE2179",
    },
    text: {
      primary: "#FFFFFF",
    },
  },
  badge: {
    background: {
      primary: "#18202A",
    },
    text: {
      primary: "#FFFFFF",
    },
  },
  tooltip: {
    background: {
      primary: "#161121",
    },
    border: {
      primary: "#DE2179",
    },
    text: {
      primary: "#FFFFFF",
    },
  },
  chartColors: expoChartColors,
});

const degen_strategy = {
  wallet: {
    tooltip: false,
    cols: {
      apr_y: false,
      progress: false,
      farm: false,
      month_earnings: false,
    },
  },
  collateral: {
    active: false,
  },
  claimable: {
    cols: {
      apr_y: false,
      month_earnings: false,
      progress: false,
    },
  },
  debt: {
    active: false,
  },
  nft: {
    active: false,
  },
};
const farming_strategy = {
  nft: {
    active: true,
  },
};

const expostrategies = {
  Farming: farming_strategy,
  Degen: degen_strategy,
  GameFi: degen_strategy,
};

export default function Multi() {
  const [expoAccount, onExpoAccount] = useState("Farming");
  // console.log(multifarmConfig[expoAccount]);
  return (
    <MultifarmProvider
      // Here the account data will be loaded based on selected account token
      token={multifarmConfig[expoAccount]}
      // theme={expoTheme}
      theme={expoTheme}
      provider="expo"
      themeColors="light"
      strategyTypesConfig={expostrategies[expoAccount]}
      technologiesUsedPlacement="none"
      {...(expoAccount === "Degen" && {
        dashboardTabs: [DASHBOARD_TABS_VARIANTS.PORTFOLIO_ALLOCATION],
      })}
    >
      {/* The example above uses additional Tabs component to add Farming & Degen toggle */}
      {/* use="expo-accounts" - only for CSS spacing, you can provide className if required. */}
      <DashboardTabs
        use="expo-accounts"
        tabs={["Farming", "Degen", "GameFi"]}
        value={expoAccount}
        onChange={onExpoAccount}
      />

      <Dashboard />
    </MultifarmProvider>
  );
}
