import React from "react";
import "../styles/footer.scss";

let year = new Date().getFullYear();

const Footer = () => {
  return (
    <>
      <footer className="footerContainer">
        <div className="footerIcons">
          <a
            href="https://t.me/+2VPVKfLOchxlYzZk"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/navIcons/telegram.svg" alt="telegram" />
          </a>

          <a
            href="https://dexscreener.com/arbitrum/0xE56C8cFaAA68469990C40844a89205C4fD7e25A4"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/navIcons/Dexscreener.svg" alt="dexScreener" />
          </a>

          <a
            href="https://mobile.twitter.com/EXPO_Capital"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/navIcons/twitter.svg" alt="twitter" />
          </a>

          <a
            href="https://medium.com/@exponentialcapital"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/navIcons/Medium.svg" alt="medium" />
          </a>

          <a
            href="mailto:contact@exponentialcapital.finance"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/navIcons/Mail.svg" alt="mail" />
          </a>
        </div>
        <div className="crText">Copyright © {year}. All Rights Reserved.</div>
      </footer>
    </>
  );
};

export default Footer;
