import React from "react";
import StatCharts from "../components/dashboard/StatChartsarb";
import Stats from "../components/dashboard/Stats";
import SupplyMechanics from "../components/supplyMechanics/SupplyMechanics";
import SupplyMechanicsHeader from "../components/supplyMechanics/SupplyMechanicsHeader"

const Defaultarb = () => {
  return (
    <div className="dashboardPage">
      {/* <Stats chainId={42161}/> */}
      <StatCharts />
        {/* <SupplyMechanicsHeader /> */}
       {/* <SupplyMechanics />   */}
    </div>
  );
};

export default Defaultarb;
