import React from "react";
import PortfolioBorder2 from "./PortfolioBorder2";
import "./portfolio2.scss";

const PortfolioTwoStat = ({ children }) => {
  return (
    <div className="portfolioTwoStatChart">
      <PortfolioBorder2 />
      <div className="portfolioTwoChartInfo">{children}</div>
    </div>
  );
};

export default PortfolioTwoStat;
