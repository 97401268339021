export const oldexpo = {
  smartContract: "0xc7260d904989febb1a2d12e46dd6679adb99a6f7",
};

export const expoConfig = {
  smartContract: "0xcfaf8edcea94ebaa080dc4983c3f9be5701d6613",
  // smartContract: "0xb637f5c5afcec800e296fff498f869b16817aaa7",
  // smartContract:"",
  smartContractarb: "0xCB24B141ECaaAd0d2c255d6F99d6f4790546a75c",
  pairaddressarb: "0xE56C8cFaAA68469990C40844a89205C4fD7e25A4",
  dividend_contract_arb:"0x0f300840e656C460Cc8C46Bd8CcAaEe8DF8a3E9D",

  liquidity_pool_pair_uniswap: "0x8E1B5164D4059FDEc87ec5D0B9c64E4FF727b1ed",
  dividend_contract: "0x49D772704A08E45502828a5434fdf0D38Aef3551",
  liquidity_wallet: "0xa1073568dca251c5be5bce11691035378f3b1585",
  treasury_wallet: "0x9e2f500a31f5b6ec0bdfc87957587307d247a595",
  marketing_wallet: "0x3ab55bc1d111a4a93c9f486ddc944d821b1effe0",
  ethereumContract: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  staking_contract: "0x7fd16176d86727C895b6d3f97b20C1903c827696",
  // staking_contract: "0x7bd676e1564d3941cea93bc765cea493bc9ce05b",
};

export const covalenthqConfig = {
  api_key: "ckey_6b4a81831d564b589e9c92c97d2",
  api_url: "https://api.covalenthq.com/v1",
};

export const etherscanConfig = {
  api_key: "TPVZV8MGSRF4REC613SN6K4SYUJN8JMFN3",
  api_url: "https://api.etherscan.io/api",
};
export const debankConfig = {
  api_url: "https://openapi.debank.com/v1/user/",
};
export const multifarmConfig = {
  Farming: "WkUCfmsMEz9U8Zrv46X1AT9r7RU3-OWL",
  Degen: "S0ZxplgIpcQZXt-i0gDOr5kjUgsBRbga",
  GameFi: "eZz-tB7C02vis9l87Le8W7ZfKbFz9Yle",
};
export const FARMING_TOKEN = "WkUCfmsMEz9U8Zrv46X1AT9r7RU3-OWL";
export const DEGEN_TOKEN = "S0ZxplgIpcQZXt-i0gDOr5kjUgsBRbga";
