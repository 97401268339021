import React from "react";
import ConnectBtn from "../components/dashboard/ConnectBtn";
import "../styles/dashboard/navbar.scss";
import { BiArrowFromRight, BiArrowFromLeft } from "react-icons/bi";

const Navbar = ({ toggleState, toggleFunc }) => {

  return (
    <nav>
      <div className="toggleIcon" onClick={() => toggleFunc(!toggleState)}>
        { toggleState ? ( <BiArrowFromLeft />) : <BiArrowFromRight /> }
      </div>
      <ConnectBtn />
    </nav>
  );
};

export default Navbar;
