import React from "react";
import PortfolioStat from "./PortfolioStat";
import Stat from "../dashboard/Stat";
import "./portfolioStatCharts.scss";
import {
  useCheckDividendPending,
  useClaimv2,
   useCompoundv2,
  useMyClaimedDividends,
  useTotalDividendDistributed,
  useTotalDividendsClaimed,
  useGetBalance,
} from "../../hooks/usedapphooksarb";
import { useEthers } from "@usedapp/core";
import { useCoingeckoTokenPrice } from "@usedapp/coingecko";
import { expoConfig } from "../../config";
import nFormatter from "../../hooks/useNumberFormatter";
import { Row, Col } from "react-bootstrap";

const PortfolioStatChartsArb = () => {
  const { send: claimreflection2 } = useClaimv2();
   const { send: compoundReflection } = useCompoundv2();
  const { account } = useEthers();
  const mybalance = useGetBalance(account);
  const eth_contract = expoConfig.ethereumContract;
  const myclaimed = useMyClaimedDividends(account);
  const eth_price = useCoingeckoTokenPrice(eth_contract, "usd");
  const pendingRewards = useCheckDividendPending(account);
  // const totalDistributed = useTotalDividendDistributed();
  // const totalclaimed = useTotalDividendsClaimed();
  console.log(pendingRewards);
  function handleClaim() {
    claimreflection2();
  }

  function handleCompoundClaim() {
    compoundReflection();
  }

  const ClaimEth = () => {
    return (
      <div className={"claimeth"} onClick={handleClaim}>
        Claim as ETH
      </div>
    );
  };

  const ClaimExpo = () => {
    return (
      <div className={"claimeth"} onClick={handleCompoundClaim}>
        Claim as Expo
      </div>
    );
  }; 

  return (
    <PortfolioStat>
      {/*  <div className="billaContainer">
             <img src="/images/corner_element.svg" alt="logo" />
           </div>  */}
      <div className="portfolioStatChartsContainer">
        <Row className="portfolioFirstRow">
          <Col gap={2} md="6" xl="6">
            <Stat
              name="My Balance"
              stat={
                mybalance
                  ? nFormatter(mybalance * 0.000000000000000001, 2) + " EXPO"
                  : "No Data"
              }
            />
          </Col>
          <Col gap={2} md="6" xl="6">
            <Stat
              name="My Pending Rewards"
              stat={
                pendingRewards
                  ? (pendingRewards * 0.000000000000000001).toFixed(4) +
                    " ETH ($" +
                    (eth_price * pendingRewards * 0.000000000000000001).toFixed(
                      2
                    ) +
                    ")"
                  : "No Data"
              }
            />
          </Col>
        </Row>
        <Row className="portfolioSecondRow">
          <Col gap={2} md="6" xl="6">
            <Stat
              name="My Claims"
              stat={
                myclaimed
                  ? (myclaimed * 0.000000000000000001).toFixed(4) +
                    " ETH ($" +
                    (eth_price * myclaimed * 0.000000000000000001).toFixed(2) +
                    ")"
                  : "No Data"
              }
            />
          </Col>
          <Col md="6" xl="6" className="claimbuttons">
            {<ClaimEth />}
            {<ClaimExpo />}
          </Col>
        </Row>
      </div>
    </PortfolioStat>
  );
};

export default PortfolioStatChartsArb;

/* <div className="firstRow">
          <Stat
            name="My Balance"
            stat={
              mybalance
                ? nFormatter(mybalance * 0.000000000000000001, 2) + " EXPO"
                : "No Data"
            }
          />
        </div>

        <div className="secondRow">
          <div className="claimed">
            <Stat
              name="My Claimed Rewards"
              stat={
                myclaimed
                  ? nFormatter(myclaimed * 0.000000000000000001, 2) + " ETH"
                  : "No Data"
              }
            />
          </div>
          <div className="pending">
            <Stat
              name="My Pending Rewards"
              stat={
                pendingRewards
                  ? nFormatter(pendingRewards * 0.000000000000000001, 4) +
                    " ETH"
                  : "No Data"
              }
              funcBtn={<ClaimEth />}
            />
          </div>
        </div> */
