import React from "react";
import StatCharts from "../components/dashboard/StatCharts";
import Stats from "../components/dashboard/Stats";
import SupplyMechanics from "../components/supplyMechanics/SupplyMechanics";
import SupplyMechanicsHeader from "../components/supplyMechanics/SupplyMechanicsHeader"

const Defaulteth= () => {
  return (
    <div className="dashboardPage">
      {/* <Stats chainId={1} /> */}
      <StatCharts />
        {/* <SupplyMechanicsHeader />
       <SupplyMechanics />   */}
    </div>
  );
};

export default Defaulteth;
