import React from "react";
import { Link } from "react-router-dom";
import "../styles/main.scss";
import TitlePic from "./TitlePic";
import SCROLLER from "../Assets/image/SCROLLER.png";
import RULER from "../Assets/image/RULER.png";

const Main = () => {
  return (
    <>
      <div key={"landing"} id="main" className="main">
        <TitlePic />

        <div className="actions">
          <div className="action">FARM.</div>
          <div className="action">TRADE.</div>
          <div className="action">SEED</div>
        </div>

        <div className="btnGrid">
          <a
            href="https://app.sushi.com/swap?outputCurrency=0xcb24b141ecaaad0d2c255d6f99d6f4790546a75c"
            target="_blank"
            rel="noreferrer"
            className="fill"
          >
            <div className="actionBtn">BUY EXPO ON SUSHISWAP</div>
          </a>
          <Link to="/dashboard" className="fill">
            <div className="actionBtn">DASHBOARD</div>
          </Link>

          {/* <a
            href="https://www.youtube.com/watch?v=F9aFpJccEGw"
            target="_blank"
            className="fill"
            rel="noreferrer"
          >
            <div className="actionBtn">WHITEPAPER</div>
          </a> */}
        </div>
      </div>
      {/* <div className="m-auto w-[98%]">
        <img src={SCROLLER} className="mt-5" />
        <p className="Scroll">SCROLL</p>
        <img src={RULER} className="b-5" height="100%" />
      </div> */}
    </>
  );
};

export default Main;

/* <AnimatePresence exitBeforeEnter> */

/* {currentView === "landing_page" && (
  <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -50 }}
    transition={{ duration: 0.5 }}
    key={"landing"}
    id="main"
    className="main"
  >
    <TitlePic />

    <motion.div className="actions">
      <div className="action">FARM.</div>
      <div className="action">TRADE.</div>
      <div className="action">SEED</div>
    </motion.div>

    <motion.div className="btnGrid">
      <a
        href="https://app.uniswap.org/#/swap?outputCurrency=0xcFAF8EDCEA94eBAA080DC4983C3f9BE5701D6613"
        target="_blank"
        rel="noreferrer"
        className="fill"
      >
        <motion.div className="actionBtn">
          BUY EXPO ON UNISWAP
        </motion.div>
      </a>
      <Link to="/dashboard" className="fill">
        <div className="actionBtn">DASHBOARD</div>
      </Link>

      <a
        href="https://www.youtube.com/watch?v=F9aFpJccEGw"
        target="_blank"
        className="fill"
        rel="noreferrer"
      >
        <div className="actionBtn">HOW TO BUY</div>
      </a>
      <a href="/Expo_Whitepaper_.pdf" target="_blank" className="fill">
        <div className="actionBtn">WHITEPAPER</div>
      </a>
    </motion.div>
  </motion.div>
)} */

/* {currentView === "video_player" && (
  <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -50 }}
    transition={{ duration: 0.5 }}
    key={"video"}
    id="main"
    className="main"
  >
    <motion.div className="videoContainer">
      <div className="videos">
        <h1 className="video-title"> How To Buy EXPO </h1>
        <video className="video" poster={poster} controls>
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </motion.div>
  </motion.div>
)} */
// </AnimatePresence>
