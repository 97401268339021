// export default function nFormatter(num, digits) {
//   const lookup = [
//     { value: 1, symbol: "" },
//     { value: 1e3, symbol: "K" },
//     { value: 1e6, symbol: "M" },
//     { value: 1e9, symbol: "B" },
//     { value: 1e12, symbol: "T" },
//   ];
//   const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
//   var item = lookup
//     .slice()
//     .reverse()
//     .find(function (item) {
//       return num >= item.value;
//     });
//   return item
//     ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
//     : Number(num).toFixed(digits);
// }

export default function nFormatter(num, digits) {
  const lookup = [
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  if (num >= 1000000) {
    // item
    // ? (num / item.value)
    //     .toLocaleString(undefined, {
    //       minimumFractionDigits: 0,
    //       maximumFractionDigits: 0,
    //     })
    //     .replace(rx, "$1") + item.symbol
    // :
    return Number(num).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else if ((num >= 1) | (digits > 3)) {
    return Number(num).toLocaleString(undefined, {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });
  } else {
    return Number(num).toLocaleString(undefined, {
      minimumFractionDigits: digits,
      minimumFractionDigits: 7,
    });
  }
}
