import { Arbitrum, DAppProvider, Mainnet } from "@usedapp/core";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing";
import Home from "./components/landing/heropage";
import {
  About,
  AboutFAAS,
  Roadmap,
  FAQs,
  HowtoBuy,
  // Team,
} from "./components/landing/About";

import Alpha from "./pages/Alpha";
import Calculator from "./pages/Calculator";
import Dashboard from "./pages/Dashboard";
import Defaulteth from "./pages/Defaulteth";
import Defaultarb from "./pages/Defaultarb";
import Portfolio from "./pages/Portfolio";
import Staking from "./pages/Staking";
import Treasury from "./pages/Treasury";
import Multi from "./pages/Multi";
import "./styles/global.scss";
const config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]:
      "https://mainnet.infura.io/v3/d5407876688e443aa25d6497fd4a1c46",
    // "https://mainnet.infura.io/v3/d5407876688e443aa25d6497fd4a1c46",
  },[Arbitrum.chainId]:
  "https://arbitrum.infura.io/v3/d5407876688e443aa25d6497fd4a1c46",

  pollingInterval: 60000,
};

function App() {
  return (
    <React.Fragment>
      <DAppProvider config={config}>
        <Router>
          <Routes>
            {/* <Route path="" element={<Landing />}></Route> */}
            <Route path="" element={<Landing />}>
              <Route path="" element={<Home />} />
              {/* <Route path="about-expo" element={<About />} /> */}
              {/* <Route path="about-faas" element={<AboutFAAS />} /> */}
              {/* <Route path="how-to-buy" element={<HowtoBuy />} /> */}
              {/* <Route path="team" element={<Team />} /> */}
              {/* <Route path="roadmap" element={<Roadmap />} /> */}
              {/* <Route path="blogs" element={<Blogs />} /> */}
              {/* <Route path="faqs" element={<FAQs />} /> */}
              {/* <Route path="contact" element={<Contact />} /> */}
            </Route>
            <Route path="/dashboard" element={<Dashboard />}>
              <Route path="" element={<Defaulteth />} />
              <Route path="eth" element={<Defaulteth />} />
              <Route path="arb" element={<Defaultarb />} />
              <Route path="treasury" element={<Multi />} />
              <Route path="portfolio" element={<Portfolio />} />
              <Route path="calculator" element={<Calculator />} />
              <Route path="staking" element={<Staking />} />
              <Route path="alpha" element={<Alpha />} />
              {/* <Route path="farm" element={<Treasury />} /> */}
            </Route>
          </Routes>
        </Router>
      </DAppProvider>
    </React.Fragment>
  );
}

export default App;
