import { NavLink, useLocation } from "react-router-dom";
import React, { useState } from "react";
import DropDown from "../Assets/image/DropDown.png";

const LandingSideBar = ({ Side, setClose }) => {
  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");
  const currentLocation = splitLocation[2];

  const [selectedBtn, setSelectedBtn] = useState(
    currentLocation ? currentLocation : "dashboard"
  );
  return (
    <div
      className={
        Side
          ? "card img-fluid card-AnimationClose"
          : "card img-fluid card-AnimationOpen"
      }
    >
      {/* card-AnimationClose */}
      <img src={DropDown} className="img card-img-top" />
      <div className="card-img-overlay">
        <ul className="list-unstyled">
          <li className="btn-text">
            + <br />
            <b className="text-underline">PAGE INDEX</b>
          </li>
          <li className="btn-text">
            <NavLink className="text-underline" to="/">
              <div
                onClick={() => {
                  setSelectedBtn("about");
                  setClose((prevState) => !prevState);
                }}
                className={`sideBtns ${
                  selectedBtn === "about" ? "selectedBtn" : ""
                } `}
              >
                HOME
              </div>
            </NavLink>
          </li>
          {/* <li className="btn-text">
            <NavLink className="text-underline" to="/about-expo">
              <div
                onClick={() => {
                  setSelectedBtn("about");
                  setClose((prevState) => !prevState);
                }}
                className={`sideBtns ${
                  selectedBtn === "about" ? "selectedBtn" : ""
                } `}
              >
                WHAT IS EXPO?
              </div>
            </NavLink>
          </li> */}
          {/* <li className="btn-text">
            <NavLink className="text-underline" to="/about-faas">
              <div
                onClick={() => {
                  setSelectedBtn("about");
                  setClose((prevState) => !prevState);
                }}
                className={`sideBtns ${
                  selectedBtn === "about" ? "selectedBtn" : ""
                } `}
              >
                WHAT IS FAAS?
              </div>
            </NavLink>
          </li> */}
          {/* <li className="btn-text">
            <NavLink className="text-underline" to="/how-to-buy">
              <div
                onClick={() => {
                  setSelectedBtn("about");
                  setClose((prevState) => !prevState);
                }}
                className={`sideBtns ${
                  selectedBtn === "about" ? "selectedBtn" : ""
                } `}
              >
                HOW TO BUY?
              </div>
            </NavLink>
          </li> */}
          {/* <li className="btn-text">
            <NavLink className="text-underline" to="/team">
              <div
                onClick={() => {
                  setSelectedBtn("about");
                  setClose((prevState) => !prevState);
                }}
                className={`sideBtns ${
                  selectedBtn === "about" ? "selectedBtn" : ""
                } `}
              >
                TEAM
              </div>
            </NavLink>
          </li> */}
          {/* <li className="btn-text">
            <NavLink className="text-underline" to="/roadmap">
              <div
                onClick={() => {
                  setSelectedBtn("about");
                  setClose((prevState) => !prevState);
                }}
                className={`sideBtns ${
                  selectedBtn === "about" ? "selectedBtn" : ""
                } `}
              >
                ROADMAP
              </div>
            </NavLink>
          </li> */}
          {/* <li className="btn-text"> */}
            {/* <NavLink className="text-underline" to="/blogs">
              <div
                onClick={() => {
                  setSelectedBtn("about");
                  setClose((prevState) => !prevState);
                }}
                className={`sideBtns ${
                  selectedBtn === "about" ? "selectedBtn" : ""
                } `}
              >
                BLOGS
              </div>
            </NavLink> */}
            {/* <a
              href="https://medium.com/@exponentialcapital"
              target="_blank"
              rel="noreferrer"
            >
              BLOGS
              {/* <img src="/images/navIcons/twitter.svg" alt="twitter" /> *
            </a> */}
          {/* </li> */}
          {/* <li className="btn-text">
            <NavLink className="text-underline" to="/faqs">
              <div
                onClick={() => {
                  setSelectedBtn("about");
                  setClose((prevState) => !prevState);
                }}
                className={`sideBtns ${
                  selectedBtn === "about" ? "selectedBtn" : ""
                } `}
              >
                FAQs
              </div>
            </NavLink>
          </li> */}
          <li className="btn-text">
            {/* <NavLink className="text-underline" to="/contact">
              <div
                onClick={() => {
                  setSelectedBtn("about");
                  setClose((prevState) => !prevState);
                }}
                className={`sideBtns ${
                  selectedBtn === "about" ? "selectedBtn" : ""
                } `}
              >
                CONTACT
              </div>
            </NavLink> */}
            {/* <a
              href="mailto:contact@exponentialcapital.finance"
              target="_blank"
              rel="noreferrer"
            >
              CONTACT
              {/* <img src="/images/navIcons/twitter.svg" alt="twitter" /> 
            </a> */}
          </li>
          <li className="btn-text">
            + <br />
            <b className="text-underline">SOCIALS</b>
          </li>
          <li className="btn-text">
            <a
              href="https://mobile.twitter.com/EXPO_Capital"
              target="_blank"
              rel="noreferrer"
            >
              TWITTER
              {/* <img src="/images/navIcons/twitter.svg" alt="twitter" /> */}
            </a>
          </li>
          <li className="btn-text">
            <a
              href="https://t.me/+2VPVKfLOchxlYzZk"
              target="_blank"
              rel="noreferrer"
            >
              TELEGRAM
            </a>
          </li>
          <li className="btn-text">
            + <br />
            <b className="text-underline">USEFUL LINKS</b>
          </li>

          <li className="btn-text">
            <NavLink className="text-underline" to="/dashboard">
              <div
                onClick={() => {
                  setSelectedBtn("about");
                  setClose((prevState) => !prevState);
                }}
                className={`sideBtns ${
                  selectedBtn === "about" ? "selectedBtn" : ""
                } `}
              >
                DASHBOARD
              </div>
            </NavLink>
          </li>

          <li className="btn-text">
            <a
              href="https://podcasts.apple.com/us/podcast/the-expo-podcast/id1613370944"
              target="_blank"
              rel="noreferrer"
            >
              PODCAST
            </a>
          </li>
          <li className="btn-text">
            <a
              href="https://dexscreener.com/ethereum/0x8e1b5164d4059fdec87ec5d0b9c64e4ff727b1ed"
              target="_blank"
              rel="noreferrer"
            >
              CHARTS
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LandingSideBar;
