import React from 'react'
import PortfolioBorder from './PortfolioBorder'
import "./portfolioStat.scss"

const PortfolioStat = ({children}) => {
    return (
        <div className="portfolioStatChart">
          <PortfolioBorder />
          <div className="portfolioChartInfo">{children}</div>
        </div>
  )
}

export default PortfolioStat